<template>
  <b-form @submit.prevent="save">
    <div class="title-actions">
      <h5 class="title">{{$t('tournaments')}} - {{title}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <label for="name">{{$t('name')}}</label>
      <b-form-input v-validate="'required|max:50'" name="name" id="name" v-model="tournament.name" :state="validateState('name')" :disabled="isCreate"/>
      <span class="error-inputs">{{ errors.first('name') }}</span>
    </div>
    <div class="form-group">
      <label for="facebook">Facebook</label>
      <b-input-group>
        <b-input-group-text slot="prepend">https://www.facebook.com/</b-input-group-text>
        <b-form-input v-validate="'max:190'" name="facebook" id="facebook" v-model="tournament.facebook" :state="validateState('facebook')" />
      </b-input-group>
      <span class="error-inputs">{{ errors.first('facebook') }}</span>
    </div>
    <div class="form-group">
      <label for="country">{{$t('country')}}</label>
      <multiselect v-validate="'required'" :state="validateState('country')" name="country" id="country" v-model="tournament.country" :options="availableCountries" deselectLabel="" track-by="name" label="name" :placeholder="$t('selectOption')" :searchable="true" :allow-empty="false" selectLabel="" selectedLabel="" :disabled="isCreate">
        <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
      </multiselect>
      <span class="error-inputs">{{ errors.first('country') }}</span>
    </div>
    <div class="form-group">
      <label for="console">{{$t('console')}}</label>
      <multiselect v-validate="'required'" :state="validateState('console')" name="console" id="console" v-model="tournament.console" :options="consoles" deselectLabel="" :placeholder="$t('selectOption')" :searchable="false" selectLabel="" selectedLabel="" :disabled="isCreate">
      </multiselect>
      <span class="error-inputs">{{ errors.first('console') }}</span>
    </div>
    <div class="form-group">
      <label for="mode">{{$t('mode') | toCapitalize}}</label>
      <select name="mode" id="mode" class="form-control" v-model="tournament.mode" v-validate="'required'" :state="validateState('mode')" :disabled="isCreate">
        <option value="NORMAL">Normal</option>
        <option value="1VS1">1vs1</option>
      </select>
      <span class="error-inputs">{{ errors.first('mode') }}</span>
    </div>
    <div class="form-group">
      <label for="description">{{$t('description')}}</label>
      <b-form-textarea name="description" id="description" v-model="tournament.description" v-validate="'required|max:64'" :state="validateState('description')" />
      <span class="error-inputs">{{ errors.first('description') }}</span>
    </div>
    <div class="form-group" v-if="user.type === 'SUPER'">
      <label for="category">{{$tc('category', 1)| toCapitalize}}</label>
      <select name="category" id="category" class="form-control" v-model="tournament.category" v-validate="'required'" :state="validateState('category')">
        <option value="">{{ $t('selectOption') }}</option>
        <option v-for="(category, i) in tournamentCategories" :key="`cat${i}`" :value="category.id">{{ category.name }}</option>
      </select>
      <span class="error-inputs">{{ errors.first('category') }}</span>
    </div>
    <div class="form-group" v-if="user.type === 'SUPER'">
      <label for="category">{{$t('hideTournament')}}</label>
      <select name="hide" id="hide" class="form-control" v-model="tournament.hide" v-validate="'required'" :state="validateState('hide')">
        <option value="">{{ $t('selectOption') }}</option>
        <option value="0">{{ $t('not') }}</option>
        <option value="1">{{ $t('yes') }}</option>
      </select>
      <span class="error-inputs">{{ errors.first('hide') }}</span>
    </div>
    <b-button type="submit" variant="primary" :disabled="loading">{{$t('save')}}</b-button>
    <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      title: '',
      tournament: {},
      availableCountries: [],
      isCreate: false,
      consoles: []
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'countries',
      'tournamentCategories',
      'user'
    ])
  },
  mounted() {
    this.consoles = window.consoles
    const tournamentId = this.$route.params.tournamentId
    if (!tournamentId) {
      this.changeTitleBar(this.$t('createTournament'))
      this.title = this.$t('createTournament')
    } else {
      this.changeTitleBar(this.$t('editTournament'))
      this.title = this.$t('editTournament')
      this.isCreate = true
      this.fetchData()
    }
    if (this.countries.length < 1) {
      this.fetchCountries()
    } else {
      this.setCountriesForSelect(this.countries)
    }
    if (this.tournamentCategories.length < 1) {
      this.fetchCategories()
    }
  },
  methods: {
    fetchCategories () {
      const path = `auth/${this.lang}/${this.console}/tournaments/categories/all`
      this.$axios.get(path).then(response => {
        this.$store.dispatch('SET_TOURNAMENT_CATEGORIES', response.data.data)
      })
    },
    fetchCountries () {
      this.$axios.get('countries').then((response) => {
        const data = response.data.data
        this.$store.dispatch('SET_COUNTRIES', data)
        this.setCountriesForSelect(data)
      })
    },
    setCountriesForSelect (countries) {
      this.availableCountries = countries.map((country) => {
        return {
          id: country.id,
          name: country.name
        }
      })
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
            const tournamentId = this.$route.params.tournamentId
            const payload = {
              name: this.tournament.name,
              description: this.tournament.description,
              mode: this.tournament.mode,
              console: this.tournament.console,
              country: this.tournament.country.id,
              facebook: this.tournament.facebook
            }
            if (this.user.type === 'SUPER') {
              payload.category = this.tournament.category
              payload.hide = this.tournament.hide
            }
            if (!tournamentId) {
              const path = `auth/${this.lang}/${this.console}/tournament/store`
              this.$axios.post(path, payload).then((response) => {
                this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
                this.$store.dispatch('ADD_TOURNAMENT', response.data.data)
                this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
              })
            } else {
              const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/update`
              this.$axios.put(path, payload).then(() => {
                this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
                this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
              })
            }
          } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      if (tournamentId) {
        const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/edit`
        this.$axios.get(path).then(response => {
          this.tournament = response.data.data
          this.tournament.hide = response.data.data.show
          this.tournament.category = response.data.data.category_id
          if (this.tournament.country) {
            const country = this.countries.filter(country => {
              return country.id === this.tournament.country
            })
            if (country.length > 0) {
              this.tournament.country = country.reduce(c => {
                return c
              })
            }
          }
        })
      } else {
        this.$toastr.error(this.$t('error404'))
        this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
      }
    }
  }
}
</script>
